import React, { useState, useEffect } from 'react';
import './Tap1.css';
import API_URL from '../config.js';

const Referral = () => {
  const [referralCode, setReferralCode] = useState('');
  const [referrals, setReferrals] = useState([]);
  const [referralPoints, setReferralPoints] = useState(0);

  useEffect(() => {
    const initialize = async () => {
      console.log('Initializing...');
      if (window.Telegram && window.Telegram.WebApp) {
        const tg = window.Telegram.WebApp;
        const user = tg.initDataUnsafe.user;
        if (user) {
          setReferralCode(user.id || 'default');
          await fetchReferrals(user.id);
        } else {
          setReferralCode('user');
          console.log('No user found, defaulting to Gamzatel');
          await fetchReferrals('Gamzatel');
        }
      } else {
        console.error('Telegram WebApp is not defined');
      }
    };

    initialize();
  }, []);

  const fetchReferrals = async (username) => {
    console.log('Fetching referrals for:', username);
    try {
      const response = await fetch(`${API_URL}/get-referrals?username=${username}`);
      const data = await response.json();
      console.log('Data received:', data);
      setReferrals(data.referrals || []);
      setReferralPoints(data.referalpoints || 0);
    } catch (error) {
      console.error('Error fetching user points:', error);
    }
  };

  const copyToClipboard = (e) => {
    const referralLink = `https://t.me/StarOriginCasualBot?start=${referralCode}`;
    navigator.clipboard.writeText(referralLink);
    changeButtonColor(e);
  };

  const shareLink = (e) => {
    const referralLink = `https://t.me/StarOriginCasualBot?start=${referralCode}`;
    const message = `Join using my referral link: ${referralLink}`;
    const url = `https://t.me/share/url?url=${encodeURIComponent(referralLink)}&text=${encodeURIComponent(message)}`;
    
    window.open(url, '_blank');

    changeButtonColor(e);
  };

  const changeButtonColor = (e) => {
    const button = e.target;
    button.style.backgroundColor = '#8345a0';
    setTimeout(() => {
      button.style.backgroundColor = '#252525';
    }, 300);
  };

  return (
    <div className="referral-container">
      <h1>Invite Friends</h1>
      <div className="referral-subtitle">and get more coins</div>
      <div className="referral-box">
        <p>Your referral link:</p>
        <div className="referral-code">
          <a href={`https://t.me/StarOriginCasualBot?start=${referralCode}`}>https://t.me/StarOriginCasualBot?start={referralCode}</a>
        </div>
      </div>
      <div className="button-container">
        <button className="referral-button" onClick={copyToClipboard}>Copy</button>
        <button className="referral-button" onClick={shareLink}>Share</button>
      </div>
      <div className="referrals-list">
        <h2>Referral Coins: {referralPoints}</h2>
        <ul>
          {referrals.map((referral, index) => (
            <li key={index}>
              <span className="referral-name">{referral}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Referral;
