import React, { useState } from 'react';
import './App.css';
import TapGame from './components/Tap';
import StarField from './StarField';
import Referral from './components/Tap1';
import Tasks from './components/Tap2';
import { FaHome, FaTasks, FaUsers, FaGamepad } from 'react-icons/fa';

function App() {
  const [activeTab, setActiveTab] = useState('tap');

  const renderTabContent = () => {
    switch (activeTab) {
      case 'tap':
        return <TapGame />;
      case 'tab1':
        return <Referral />;
      case 'tab2':
        return <Tasks />;
      default:
        return <TapGame />;
    }
  };

  return (
    <div className="App">
      <StarField />
      {renderTabContent()}
      <div className="navbar">
        <div className={`nav-item ${activeTab === 'tap' ? 'active' : ''}`} onClick={() => setActiveTab('tap')}>
          <FaGamepad className="nav-icon" />
          <span className="nav-text"></span>
        </div>
        <div className={`nav-item ${activeTab === 'tab1' ? 'active' : ''}`} onClick={() => setActiveTab('tab1')}>
          <FaHome className="nav-icon" />
          <span className="nav-text"></span>
        </div>
        <div className={`nav-item ${activeTab === 'tab2' ? 'active' : ''}`} onClick={() => setActiveTab('tab2')}>
          <FaTasks className="nav-icon" />
          <span className="nav-text"></span>
        </div>
      </div>
    </div>
  );
}

export default App;
