import React, { useState, useEffect } from 'react';
import './Tap2.css';
import API_URL from '../config.js';

const Tasks = () => {
  const [isTwitterTaskCompleted, setIsTwitterTaskCompleted] = useState(false);
  const [username, setUsername] = useState('');

  useEffect(() => {
    const fetchCompletedTasks = async () => {
      let currentUsername = '';

      if (window.Telegram && window.Telegram.WebApp) {
        const tg = window.Telegram.WebApp;
        const user = tg.initDataUnsafe.user;

        if (user) {
          currentUsername = user.id;
        }
      }

      try {
        const response = await fetch(`${API_URL}/get-completed-tasks?username=${currentUsername}`);
        const data = await response.json();

        if (data.tasks && data.tasks.includes('FollowTwitter')) {
          setIsTwitterTaskCompleted(true);
        }

        setUsername(currentUsername);
      } catch (error) {
        console.error('Error fetching completed tasks:', error);
      }
    };

    fetchCompletedTasks();
  }, []);

  const handleFollowTwitter = async () => {
    const pointsToAdd = 100;

    try {
      await fetch(`${API_URL}/update-tasks-points`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, points: pointsToAdd, task: "FollowTwitter" }),
      });

      if (window.Telegram && window.Telegram.WebApp) {
        const tg = window.Telegram.WebApp;
        tg.openLink('https://twitter.com/StarOrigin_/status/1793249433691247075', { try_instant_view: false });
      } else {
        window.open('https://x.com/StarOrigin_/status/1793249433691247075', '_blank');
      }

      setIsTwitterTaskCompleted(true);
    } catch (error) {
      console.error('Error updating user points:', error);
    }
  };

  return (
    <div className="task-container">
      <h1>Tasks</h1>
      <div className="task-item">
        <p>Follow Twitter</p>
        <button 
          className="task-button" 
          onClick={handleFollowTwitter} 
          disabled={isTwitterTaskCompleted}
        >
          {isTwitterTaskCompleted ? 'Completed' : '+100 Star'}
        </button>
      </div>
    </div>
  );
};

export default Tasks;
