import React, { useEffect } from 'react';
import './StarField.css';

const StarField = () => {
  useEffect(() => {
    const starField = document.getElementById('star-field');
    const numStars = 100;

    for (let i = 0; i < numStars; i++) {
      const star = document.createElement('div');
      star.className = 'star';
      star.style.left = `${Math.random() * 100}%`;
      star.style.top = `${Math.random() * 100}%`;
      star.style.animationDelay = `${Math.random() * 5}s`;
      starField.appendChild(star);
    }
  }, []);

  return <div id="star-field"></div>;
};

export default StarField;
