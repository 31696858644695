import React, { useState, useEffect } from 'react';
import './Tap.css';
import API_URL from '../config.js';

var TotalCount = 0;
var SessionPoints = 0;
var bFirstUpdate = false;
var timerID = 0;

const TapGame = () => {
  const [tapCount, setTapCount] = useState(TotalCount);
  const [userId, setUserId] = useState(null);
  const [username, setUsername] = useState('User');

  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      const tg = window.Telegram.WebApp;
      tg.expand();
      setUsername(tg.initDataUnsafe.user.username || "User");
      setUserId(tg.initDataUnsafe.user.id);
      //setUsername("Gamzatel");
    } else {
      console.error('Telegram WebApp is not defined');
    }

    const handleLoad = async () => {
      if (window.Telegram && window.Telegram.WebApp) {
        const tg = window.Telegram.WebApp;
        await fetchUserPoints(tg.initDataUnsafe.user.id);
        //setUsername("Gamzatel");
      }
    }; 

    if (document.readyState === 'complete') {
      if (!bFirstUpdate) {
        handleLoad();
        bFirstUpdate = true;
      }
    }
    else {
      window.addEventListener('load', handleLoad)
    }

    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, [username, userId, tapCount, SessionPoints]);

  const fetchUserPoints = async (username) => {
    console.log("data.points");
    try {
      const response = await fetch(`${API_URL}/get-points?username=${username}`);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const blob = await response.blob();
      const text = await blob.text();
      const data = JSON.parse(text);

      if (data.points !== undefined) {
        TotalCount = data.points;
        setTapCount(TotalCount);
      }
    } catch (error) {
      console.error('Error fetching user points:', error);
      localStorage.setItem('fetchError', error.message);
    }
  };

  const handleTap = () => {
    TotalCount = tapCount + 1;
    SessionPoints++;
    setTapCount(TotalCount);
    clearTimeout(timerID);
    timerID = setTimeout(() => {
        const data = JSON.stringify({ username: userId, points: SessionPoints });
        const blob = new Blob([data], { type: 'application/json' });
        navigator.sendBeacon(`${API_URL}/update-user-points`, blob);
        SessionPoints = 0;
    }, 200);
    triggerWaveEffect();
  };

  const triggerWaveEffect = () => {
    const wave = document.createElement('div');
    wave.classList.add('wave-effect');
    document.getElementById('tap-button').appendChild(wave);
    setTimeout(() => {
      wave.classList.add('active');
    }, 0);
    setTimeout(() => {
      wave.remove();
    }, 2000); // Remove the wave after the animation duration
  };

  return (
    <div className="game-container">
      <div className="username-container">
        <h1>{username}</h1>
      </div>
      <div className="points-container">
        <div id="tap-count">{tapCount}</div>
      </div>
      <div className="button-container">
        <button id="tap-button" onClick={handleTap}>
          <span></span>
        </button>
      </div>
    </div>
  );
};

export default TapGame;
